<template>
    <div>
        <h1 class="title-text">Verifying your token...</h1>
        <LoadingSpinner />
    </div>
</template>

<script>
import LoadingSpinner from '../LoadingSpinner.vue';

export default {
    name: 'LoadingVerifyToken',
    components: { LoadingSpinner }
}
</script>

<style scoped>
.title-text {
    color: var(--color-purple);
    font-family: Helvetica, Arial;
}
</style>