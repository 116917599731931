import { api } from '../api/stagingApi.js';

export async function verifyEmail({ token }) {
  try {
    const response = await api.post(`/user/email/verify`, {
      token,
    });
    return response.data;
  } catch (error) {
    return {
      error: true,
      message: 'An error has occurred',
      details: '',
    };
  }
}

export async function resendVerificationEmail({ email }) {
  try {
    const response = await api.post(`/user/email/resendverify`, {
      email,
    });
    return response.data;
  } catch (error) {
    return {
      error: true,
      message: 'An error has occurred',
      details: '',
    };
  }
}
