<script>
import { mapActions } from "vuex";
import router from "../router";

import { verifyEmail, resendVerificationEmail } from '../services/email';
import { getInitials } from "../utils/textUtilities";
import { reinitializeSocketWithToken } from "../services/socket_background.js";
import PleaseVerifyEmail from "../components/Login/PleaseVerifyEmail.vue";
import LoadingVerifyToken from "../components/Login/LoadingVerifyToken.vue";
import PopUpCardModal from "../components/shared/PopUpCardModal.vue";

export default {
    name: 'VerifyEmailPage',
    components: {
        PleaseVerifyEmail,
        LoadingVerifyToken,
        PopUpCardModal
    },
    async mounted() {
        window.scrollTo(0, 0);
        this.getEmailFromLocalStorage();
        this.loginWithToken();
    },
    methods: {
        ...mapActions(["logoutSession", "setUserSessionAction"]),
        closePopUp() {
            this.$refs.modalError.hide();
        },
        async loginWithToken() {
            try {
                const token = this.$route.query.token;
                if (!token) return;
                this.isVerifyingToken = true;
                const res_data = await verifyEmail({ token });
                this.logoutSession();
                if (res_data.error === false) {
                    reinitializeSocketWithToken(res_data.token);
                    localStorage.setItem("token", res_data.token);
                    localStorage.setItem("refresh_token", res_data.refresh_token);
                    const save_user = {
                        loggedIn: true,
                        email: res_data.user.email,
                        name: res_data.user.name,
                        id_group: res_data.user.id_group,
                        initials: getInitials(res_data.user.name),
                        image: res_data.user.image,
                        permissions: res_data.permissions,
                        suscription: res_data.user.suscription
                    };
                    localStorage.setItem("user_data", JSON.stringify(save_user));
                    this.setUserSessionAction(save_user);
                    if (res_data?.user?.show_welcome == true) {
                        router.push("/editproject?welcome=true");
                    } else {
                        router.push("/editproject");
                    }
                } else {
                    // if (res_data.verified == true) {
                    //     router.push({ name: "login" });
                    // }
                    if (res_data.message) {
                        this.modalMessage = res_data.message;
                        this.$refs.modalError.showModal();
                        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
                        delay(1500)
                            .then(() => {
                                router.push({ name: "login" });
                            });
                    } else {
                        this.modalMessage = 'An error has occurred.';
                        this.$refs.modalError.showModal();
                    }
                }
                this.isVerifyingToken = false;
            } catch (error) {
                console.log(error);
            }
        },
        getEmailFromLocalStorage() {
            this.email = localStorage.getItem('email');
        },
        async resendVerificationEmail() {
            const email = this.email;
            if (!email) {
                return;
            }
            const resp = await resendVerificationEmail({ email });
            if (!resp.error) {
                localStorage.removeItem('email');
                this.showVerificationEmail = false;
            }
        }
    },
    data() {
        return {
            isVerifyingToken: false,
            showVerificationEmail: true,
            email: '',
            modalMessage: '',
            isErrorToken: false,
        }
    },
}
</script>

<template>
    <div class="verify-email-container">
        <section class="main-section-container">
            <PleaseVerifyEmail v-if="!isVerifyingToken" :showVerificationEmail="showVerificationEmail"
                :resendVerificationEmail="resendVerificationEmail" />
            <LoadingVerifyToken v-else />
        </section>

        <PopUpCardModal ref="modalError" :closePopUp="closePopUp" :titleCard="'Information'" :textMsg="modalMessage"
            :isOk="true" />

        <footer class="footer-bobble-graph">
            <img class="w-100" src="../assets/Loginbackground.svg" alt="bobble-graph" width="100%" height="100%">
        </footer>
    </div>
</template>


<style scoped src="../assets/styles/views/VerifyEmailPage.css"></style>../composables/useAuthWithGoogle