<template>
    <div>
        <router-link to="/login">
            <AliceLogoMark />
        </router-link>
        <div class="verify-email-text-container">
            <h1 class="title font-h weight-400">Please verify your email address</h1>
            <template v-if="showVerificationEmail">
                <p class="subtitle font-h weight-400" @click="resendVerificationEmail">Please check your inbox for our
                    verification email.<br>
                    No email was received? <span class="underline">Resend email.</span></p>
            </template>
            <template v-else>
                <p class="subtitle font-h weight-400">Please check your inbox for our verification email.<br>
                    If not no email was received, please click on <span class="text-green">✓ Email sent</span>.</p>
            </template>

            <LineDividerWithText />

            <CustomGoogleSignInButton :marginBottom="''" />

            <ButtonGoogle ref="googleButtonRef" @on_google_login="handleGoogleButtonClick" />
        </div>
    </div>
</template>

<script>
import AliceLogoMark from '../shared/AliceLogoMark.vue';
import ButtonGoogle from './ButtonGoogle.vue';
import CustomGoogleSignInButton from '../shared/session/CustomGoogleSignInButton.vue';
import LineDividerWithText from '../shared/LineDividerWithText.vue';
import { useAuthWithGoogle } from '../../composables/useAuthWithGoogle';

export default {
    name: 'PleaseVerifyEmail',
    components: {
        AliceLogoMark,
        ButtonGoogle,
        CustomGoogleSignInButton,
        LineDividerWithText,
    },
    props: {
        showVerificationEmail: {
            type: Boolean,
        },
        resendVerificationEmail: {
            type: Function,
            default: () => { },
        }
    },
    methods: {
        async handleGoogleButtonClick(data) {
            const { loginWithGoogle } = useAuthWithGoogle();

            await loginWithGoogle(data);
        },
    }
}
</script>

<style scoped src="../../assets/styles/views/VerifyEmailPage.css"></style>../composables/useAuthWithGoogle